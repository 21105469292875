import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/materials/chromoly-pipe"
import BgImage from "../../assets/images/2017/09/pipe-stack-slide.jpg"

const ChromolyPipePage = () => {
  const title = "Chromoly Pipe"
  const content = "Offshore oil and gas and power generation piping"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default ChromolyPipePage
